import { FC, Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ClipboardDocumentIcon, LinkIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from '../../app/hooks';
import {
  fetchManualAppliedLeadAsync,
  fetchManualResetToNewLeadAsync,
  fetchPostRejectLeadAsync,
} from '../../features/leads/leadsSlice';
import { JobDetailsPartial } from '../job/jobDetails.partial';

interface Props {
  onClose: () => void;
  lead: any;
}

export const LeadPreviewPartial: FC<Props> = ({ onClose, lead }) => {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const dispatch = useAppDispatch();

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/*<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">*/}
                    {/*  <ExclamationTriangleIcon*/}
                    {/*    className="h-6 w-6 text-red-600"*/}
                    {/*    aria-hidden="true"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      {/*<Dialog.Title*/}
                      {/*  as="h3"*/}
                      {/*  className="text-base font-semibold leading-6 text-gray-900 flex items-center"*/}
                      {/*>*/}
                      {/*  {lead?.job?.title}*/}
                      {/*  <a*/}
                      {/*    href={lead.job?.url}*/}
                      {/*    className="pl-2"*/}
                      {/*    target="_blank"*/}
                      {/*    rel="noreferrer"*/}
                      {/*  >*/}
                      {/*    <LinkIcon className="w-5 h-5" />*/}
                      {/*  </a>*/}
                      {/*</Dialog.Title>*/}
                      <div className="mt-2">
                        {lead.job && <JobDetailsPartial job={lead.job} />}
                        <h3 className="text-xs font-bold my-2 flex items-center gap-2">
                          Cover letter{' '}
                          <button
                            onClick={() =>
                              navigator.clipboard.writeText(lead.coverLetter)
                            }
                          >
                            <ClipboardDocumentIcon className="w-4 h-4" />
                          </button>
                        </h3>
                        <p className="text-sm text-gray-500 whitespace-break-spaces">
                          {lead.coverLetter}
                        </p>
                        {lead.questions?.length > 0 && (
                          <>
                            <h3 className="text-xs font-bold my-2">Q/A</h3>
                            <span className="text-sm text-gray-500 flex flex-col gap-2">
                              {lead.questions.map((q: any) => (
                                <div key={q.question} className="">
                                  <div className="font-bold flex items-center gap-2">
                                    {q.question}{' '}
                                    <button
                                      onClick={() =>
                                        navigator.clipboard.writeText(q.answer)
                                      }
                                    >
                                      <ClipboardDocumentIcon className="w-4 h-4" />
                                    </button>
                                  </div>
                                  <div className="italic">{q.answer}</div>
                                </div>
                              ))}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {(lead.status === 'NEW' ||
                    lead.status === 'CONTENT_READY') && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to reject this lead?',
                          )
                        ) {
                          dispatch(fetchPostRejectLeadAsync(lead._id));
                        }
                      }}
                    >
                      REJECT
                    </button>
                  )}

                  {(lead.status === 'NEW' ||
                    lead.status === 'CONTENT_READY') && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to set this lead as APPLIED?',
                          )
                        ) {
                          dispatch(fetchManualAppliedLeadAsync(lead._id));
                        }
                      }}
                    >
                      APPLIED
                    </button>
                  )}

                  {(lead.status === 'CONTENT_READY' ||
                    lead.status === 'USER_REJECTED') && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-amber-950 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to reset back to status NEW?',
                          )
                        ) {
                          dispatch(fetchManualResetToNewLeadAsync(lead._id));
                        }
                      }}
                    >
                      RESET TO NEW
                    </button>
                  )}

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <pre>
                    <code>{JSON.stringify(lead, null, 2)}</code>
                  </pre>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
