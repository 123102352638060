export const LoginFormPartial = () => {
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <a
                href={`https://www.upwork.com/ab/account-security/oauth2/authorize?response_type=code&client_id=${
                  process.env.REACT_APP_UPWORK_CLIENT_ID
                }&redirect_uri=${encodeURIComponent(
                  String(process.env.REACT_APP_UPWORK_API_REDIRECT_URL),
                )}&state=undefined`}
                className="flex w-full justify-center rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in with Upwork
              </a>
            </div>
          </form>

          {/*<p className="mt-10 text-center text-sm text-gray-500">*/}
          {/*  Not a member?{' '}*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"*/}
          {/*  >*/}
          {/*    Start a 14 day free trial*/}
          {/*  </a>*/}
          {/*</p>*/}
        </div>
      </div>
    </>
  );
};
