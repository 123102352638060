import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { DashboardPage } from './pages/protected/dashboard.page';
import { LoginPage } from './pages/login.page';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  fetchProfileAsync,
  selectUser,
  selectUserIsLoading,
} from './features/user/userSlice';
import { FiltersPage } from './pages/protected/filters.page';
import { ProfilePage } from './pages/protected/profile.page';
import { AuthCallbackPage } from './pages/auth/callback.page';
import axios from 'axios';
import { Layout } from './partials/layout/layout.layout';
import { LoaderComponent } from './components/loaders/loader.component';
import { LeadsPage } from './pages/protected/leads.page';
import { CoverLetterPage } from './pages/protected/coverLetter.page';

function App() {
  let auth = useAppSelector(selectUser);
  let authState = useAppSelector(selectUserIsLoading);
  const dispatch = useAppDispatch();

  const [isAuthFetchInitialized, setIsAuthFetchInitialized] = useState(false);

  useEffect(() => {
    console.log({
      'auth?.ref': auth?.ref,
      "axios.defaults.headers.common['Authorization']":
        axios.defaults.headers.common['Authorization'],
      "localStorage.getItem('access_token')":
        localStorage.getItem('access_token'),
      authState,
      isAuthFetchInitialized,
    });
    if (
      !auth?.ref &&
      axios.defaults.headers.common['Authorization'] &&
      localStorage.getItem('access_token') &&
      authState === 'idle' &&
      !isAuthFetchInitialized
    ) {
      setIsAuthFetchInitialized(true);
      dispatch(fetchProfileAsync());
    } else if (
      authState === 'loaded' ||
      !localStorage.getItem('access_token') ||
      !axios.defaults.headers.common['Authorization']
    ) {
      setIsAuthFetchInitialized(false);
    }
  }, [dispatch, authState, isAuthFetchInitialized, auth]);

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    let auth = useAppSelector(selectUser);
    let location = useLocation();

    if (
      !auth?.ref &&
      (authState !== 'idle' ||
        !Boolean(axios.defaults.headers.common['Authorization']))
    ) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Layout>{children}</Layout>;
  };

  console.log({
    auth,
    authState,
  });

  if (authState === 'loading') {
    return (
      <>
        <LoaderComponent />
      </>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/callback" element={<AuthCallbackPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path="/filters"
          element={
            <RequireAuth>
              <FiltersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/leads"
          element={
            <RequireAuth>
              <LeadsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/cover-letter"
          element={
            <RequireAuth>
              <CoverLetterPage />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
