import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchAuthorizationAsync,
  selectUser,
} from '../../features/user/userSlice';

interface Props {}

let isLoggingIn = false;

export const AuthCallbackPage: FC<Props> = ({}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let auth = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  // const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    let code = searchParams.get('code');
    if (Boolean(code) && code !== null && !isLoggingIn) {
      isLoggingIn = true;
      dispatch(fetchAuthorizationAsync(code));
      code = null;
      searchParams.delete('code');
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (auth?.name) {
      console.log('🚀 auth', auth);
      navigate('/');
    }
  }, [auth, navigate]);

  return <>...</>;
};
