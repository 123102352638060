import { FC, useEffect, useState } from 'react';
import { ExperienceForm } from '../../partials/forms/experience/experience.form';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchDeleteExperienceAsync,
  fetchExperienceListAsync,
  fetchPostExperienceAsync,
  selectExperience,
  selectExperienceStatus,
} from '../../features/experience/experienceSlice';
import { TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

interface Props {}

export const ProfilePage: FC<Props> = ({}) => {
  const [isAddOpen, setIsAddOpen] = useState(false);

  const experience = useAppSelector(selectExperience);
  const experienceStatus = useAppSelector(selectExperienceStatus);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchExperienceListAsync());
  }, []);

  const handleNewSubmit = (question: string, answer: string) => {
    dispatch(fetchPostExperienceAsync({ question, answer }));
    setIsAddOpen(false);
  };

  const handleDelete = (exp: any) => {
    if (window.confirm('Are you sure you want to delete this?')) {
      dispatch(fetchDeleteExperienceAsync(exp));
    }
  };

  return (
    <>
      <header className="bg-white shadow">
        <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Profile
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-screen-2xl py-6 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-end">
            <button onClick={() => setIsAddOpen(!isAddOpen)}>
              <PlusCircleIcon className="block h-10 w-10" />
            </button>
          </div>

          {isAddOpen && (
            <div className="p-4 border-2 border-dashed my-4 bg-white border-gray-200 rounded">
              <ExperienceForm
                excludeQuestions={experience.map((exp: any) => exp.question)}
                onSubmit={handleNewSubmit}
              />
            </div>
          )}

          <div className="mt-4 space-y-4">
            {experienceStatus === 'loaded' &&
              experience.map((exp: any) => (
                <div
                  key={exp._id}
                  className="bg-white border-2 border-gray-200 border-dashed p-4 rounded-md shadow-sm relative"
                >
                  <div className="absolute right-4">
                    <button onClick={() => handleDelete(exp)}>
                      <TrashIcon className="block h-5 w-5 hover:animate-pulse" />
                    </button>
                  </div>
                  <h2 className="font-bold mb-2 pr-14">{exp.question}</h2>
                  <p className="text-sm">{exp.answer}</p>
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  );
};
