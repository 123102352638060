import axios from 'axios';

export const fetchLeadsList = async () => {
  const r = await axios.get('leads/mine');
  return r;
};

export const fetchPostManualCreateLead = async (job: any) => {
  const r = await axios.post(`leads/job-to-lead/${job.id}`);
  return r;
};

export const fetchPostRejectLead = async (leadId: string) => {
  const r = await axios.post(`leads/lead-reject/${leadId}`);
  return r;
};

export const fetchManualAppliedLead = async (leadId: string) => {
  const r = await axios.post(`leads/lead-manual-applied/${leadId}`);
  return r;
};

export const fetchManualResetToNewLead = async (leadId: string) => {
  const r = await axios.post(`leads/reset-content/${leadId}`);
  return r;
};
