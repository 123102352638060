import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAuthorization, fetchUserProfile } from './userAPI';
import axios from 'axios';

export interface UserState {
  value: any;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: UserState = {
  value: undefined,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchProfileAsync = createAsyncThunk(
  'user/fetchProfile',
  async () => {
    const response = await fetchUserProfile();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchAuthorizationAsync = createAsyncThunk(
  'user/fetchProfile',
  async (code: string) => {
    try {
      const response = await fetchAuthorization(code);
      if (response?.data?.access_token) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response?.data?.access_token}`;
        localStorage.setItem('access_token', response?.data?.access_token);
      }
      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      // location.href = '/login';
      console.error(e);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reset: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.value = action.payload;
      })
      .addCase(fetchProfileAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { reset } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.value;
export const selectUserIsLoading = (state: RootState) => state.user.status;

export default userSlice.reducer;
