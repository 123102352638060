import axios from 'axios';

export const initAxios = () => {
  axios.defaults.baseURL = String(process.env.REACT_APP_BASE_API_URL);
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  if (localStorage.getItem('access_token')) {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('access_token')}`;
  }
  axios.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    // if error is 401
    if (
      error.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      // request for a new token
      // return getAuthToken().then((response) => {
      //   // update the error config with new token
      //   error.config.__isRetryRequest = true;
      //   error.config.headers.token = localStorage.getItem('accessToken');
      //   return client(error.config);
      // });
      localStorage.removeItem('access_token');
      axios.defaults.headers.common['Authorization'] = undefined;
      // eslint-disable-next-line no-restricted-globals
      location.href = '/login';
    }
  });
};

export const resetAxios = () => {
  axios.defaults.headers.common['Authorization'] = undefined;
  localStorage.removeItem('access_token');
};
