import axios from 'axios';

export const fetchExperienceList = async () => {
  const r = await axios.get('experience/me/experience');
  return r;
};

export const fetchPostExperience = async (experience: any) => {
  const r = await axios.post(`experience/me/experience`, experience);
  return r;
};

export const fetchDeleteExperience = async (experience: any) => {
  const r = await axios.delete(`experience/me/experience/${experience._id}`);
  return r;
};
