import { FC, ReactNode } from 'react';
import { resetAxios } from '../../lib/axios.lib';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { reset, selectUser } from '../../features/user/userSlice';
import { TopNavPartial } from '../nav/topNav.partial';
import { useLocation } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  let auth = useAppSelector(selectUser);

  const loc = useLocation();
  console.log(loc);

  return (
    <TopNavPartial
      user={{
        name: auth?.first_name,
        email: '...',
        imageUrl: auth?.portrait_50_img,
      }}
      navigation={[
        { name: 'Dashboard', href: '/', current: loc.pathname === '/' },
        {
          name: 'Filters',
          href: '/filters',
          current: loc.pathname === '/filters',
        },
        {
          name: 'Leads',
          href: '/leads',
          current: loc.pathname === '/leads',
        },
        {
          name: 'Cover letter',
          href: '/cover-letter',
          current: loc.pathname === '/cover-letter',
        },
        {
          name: 'Profile',
          href: '/profile',
          current: loc.pathname === '/profile',
        },
      ]}
      userNavigation={[
        { name: 'Your Profile', href: '#' },
        { name: 'Settings', href: '#' },
        {
          name: 'Sign out',
          href: '#',
          onClick: () => {
            resetAxios();
            dispatch(reset);
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          },
        },
      ]}
    >
      <main>{children}</main>
    </TopNavPartial>
  );
};
