import { FC } from 'react';
import { useFormik } from 'formik';
import { profileQuestionnaireLib } from '../../../lib/profileQuestionnaire.lib';
import { camelCaseToWords } from '../../../lib/camelCaseToWords.lib';
import * as Yup from 'yup';

interface Props {
  onSubmit: (question: string, answer: string) => void;
  excludeQuestions: string[];
}

export const ExperienceForm: FC<Props> = ({ onSubmit, excludeQuestions }) => {
  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
    },
    validationSchema: Yup.object({
      question: Yup.string().min(20).max(4095).label('Question').required(),
      answer: Yup.string().min(5).max(4095).label('Answer').required(),
    }),
    onSubmit: (values) => {
      onSubmit(values.question, values.answer);
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="">
        <div>
          <label
            htmlFor="price"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Question
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="text"
              name="question"
              id="question"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-[386px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="What's your full name?"
              value={formik.values.question}
              onChange={formik.handleChange}
            />
            <div className="absolute inset-y-0 right-0 flex items-center border-l-2">
              <label htmlFor="currency" className="sr-only">
                Predefined questions
              </label>
              <select
                id="currency"
                name="currency"
                className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm max-w-sm"
                onChange={(e) => {
                  if (formik.values.question === '') {
                    formik.setFieldValue('question', e.target.value);
                  }
                }}
              >
                <option value="">Pick a question</option>
                {Object.keys(profileQuestionnaireLib).map((key) => (
                  <optgroup key={key} label={camelCaseToWords(key)}>
                    {Object.keys(profileQuestionnaireLib[key])
                      .filter(
                        (key2) =>
                          !excludeQuestions.includes(
                            profileQuestionnaireLib[key][key2],
                          ),
                      )
                      .map((key2) => (
                        <option key={key2}>
                          {profileQuestionnaireLib[key][key2]}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label
          htmlFor="price"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Your answer
        </label>
        <textarea
          rows={5}
          name="answer"
          placeholder="John Doe Junior the 2nd"
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={formik.values.answer}
          onChange={formik.handleChange}
        ></textarea>
      </div>

      <div className="flex flex-row justify-end">
        <button
          type={'submit'}
          className="px-4 py-2 rounded-md text-white bg-green-700"
          disabled={!formik.isValid}
          onClick={formik.submitForm}
        >
          Submit
        </button>
      </div>

      {formik.errors &&
        Object.keys(formik.errors).map((k) => (
          <div className="text-red-900 text-xs">
            {/*// @ts-ignore*/}
            <strong>{k}</strong>: {formik.errors[k]}
          </div>
        ))}
    </div>
  );
};
