import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  closeLead,
  fetchLeadsListAsync,
  selectLeads,
  selectLeadsStatus,
  selectOpenLead,
} from '../../features/leads/leadsSlice';
import { ColumnComponent } from '../../components/kanban/column/column.component';
import { CardComponent } from '../../components/kanban/card/card.component';
import { LeadPreviewPartial } from '../../partials/leads/leadPreview.partial';
import { hoursAgo } from '../../lib/hoursAgo.lib';

interface Props {}

const CONSIDER_ROTTING_IN_HOURS = 48;

export const LeadsPage: FC<Props> = ({}) => {
  const leads = useAppSelector(selectLeads);
  const openLead = useAppSelector(selectOpenLead);
  const leadsStatus = useAppSelector(selectLeadsStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLeadsListAsync());
  }, []);

  return (
    <>
      <header className="bg-white shadow">
        <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Leads
          </h1>
        </div>
      </header>
      {openLead && (
        <LeadPreviewPartial
          lead={openLead}
          onClose={() => dispatch(closeLead())}
        />
      )}
      <main>
        <div className="mx-auto ---max-w-screen-2xl py-6 sm:px-6 lg:px-8 pt-8 flex overflow-x-scroll gap-4 h-full basis-auto shrink grow-0">
          <ColumnComponent title="New">
            {leads
              ?.filter((l: any) => l.status === 'NEW')
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Content Ready">
            {leads
              ?.filter((l: any) => l.status === 'CONTENT_READY')
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Applied">
            {leads
              ?.filter(
                (l: any) =>
                  (l.status === 'APPLICATION_SENT' ||
                    l.status === 'APPLICATION_SENT_USER_MANUAL_OVERRIDE') &&
                  l.application?.status?.toString() !== '7' &&
                  l.application?.status?.toString() !== '8',
              )
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          {/*<ColumnComponent title="Seen">*/}
          {/*  {leads*/}
          {/*    ?.filter((l: any) => l.status === 'APPLICATION_SEEN')*/}
          {/*    .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}*/}
          {/*</ColumnComponent>*/}
          <ColumnComponent title="In contact">
            {leads
              ?.filter(
                (l: any) =>
                  (l.status === 'APPLICATION_RESPONDED' ||
                    l.application?.status?.toString() === '7') &&
                  hoursAgo(
                    l.job?.date_created ?? l.job?.op_ctime ?? l.createdAt,
                  ) <= CONSIDER_ROTTING_IN_HOURS,
              )
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Rotting">
            {leads
              ?.filter(
                (l: any) =>
                  (l.status === 'APPLICATION_STALL' ||
                    l.application?.status?.toString() === '7') &&
                  hoursAgo(
                    l.job?.date_created ?? l.job?.op_ctime ?? l.createdAt,
                  ) > CONSIDER_ROTTING_IN_HOURS,
              )
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Won">
            {leads
              ?.filter((l: any) => l.status === 'WON')
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Lost">
            {leads
              ?.filter((l: any) => l.status === 'LOST')
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
          <ColumnComponent title="Rejected">
            {leads
              ?.filter((l: any) => l.status === 'USER_REJECTED')
              .map((lead: any) => <CardComponent lead={lead} key={lead._id} />)}
          </ColumnComponent>
        </div>
      </main>
    </>
  );
};
