import { FC } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { openLead } from '../../../features/leads/leadsSlice';
import {
  FunnelIcon,
  QuestionMarkCircleIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

interface Props {
  lead: any;
}

export const CardComponent: FC<Props> = ({ lead }) => {
  const dispatch = useAppDispatch();

  const hoursAgo = Math.floor(
    DateTime.local().diff(
      DateTime.fromISO(
        lead.job?.date_created ?? lead.job?.op_ctime ?? lead.createdAt,
      ),
      'hours',
    ).hours,
  );

  const DollarSign = () => <span className="font-bold text-green-700">$</span>;

  return (
    <div
      className="bg-white p-4 border-2 border-dashed border-gray-200 rounded-md w-[290px] w-auto"
      onClick={() => dispatch(openLead(lead))}
    >
      {/*<div>*/}
      {/*  [ {lead.status} / {lead.application?.status} ]*/}
      {/*</div>*/}
      <div className="flex items-center mb-2">
        {lead.filter?.filterTitle && (
          <h3 className="px-2 py-1 text-xs text-white bg-green-700 flex items-center gap-2 rounded-2xl pr-4">
            <FunnelIcon className="w-3 h-3" />
            <span>{lead.filter.filterTitle}</span>
          </h3>
        )}
        {!lead.filter?.filterTitle && (
          <h3 className="px-2 py-1 text-xs text-white bg-gray-400 flex items-center gap-2 rounded-2xl pr-4">
            <FunnelIcon className="w-3 h-3" />
            <span>Manual / Auto</span>
          </h3>
        )}
        <div className="grow flex justify-end pl-2 items-center">
          <div className="flex gap-1 items-center">
            <QuestionMarkCircleIcon className="w-3 h-3" />
            <span className="text-xs">{lead.questions.length}</span>
          </div>
        </div>
      </div>
      <h2 className="text-sm font-bold">
        {lead.job?.title ?? lead.job?.op_title}
      </h2>
      <div className="flex items-center mt-3 justify-between">
        <div
          className={`flex items-center gap-1  ${
            hoursAgo > 12 ? 'text-red-800' : ''
          }`}
        >
          <ClockIcon className="w-4 h-4" />
          <span className="text-xs font-bold">
            {DateTime.fromISO(
              lead.job?.date_created ?? lead.job?.op_ctime ?? lead.createdAt,
            ).toRelative()}
          </span>
        </div>

        {lead.application?.terms?.chargeRate?.amount && (
          <div className="flex items-center bg-gray-800 py-1 px-2 rounded-md text-white">
            <span className="text-[8px] pr-1">
              {lead.application?.terms?.chargeRate?.currency}
            </span>
            <span className="text-[12px] font-bold">
              {lead.application?.terms?.chargeRate?.amount}
            </span>
            {lead.job?.job_type === 'Hourly' && (
              <span className="text-[8px] font-bold">/h</span>
            )}
          </div>
        )}

        {lead.job?._id && (
          <div className="text-xs">
            {lead.job.job_type === 'Hourly' && (
              <span>
                <DollarSign />
                {lead.job.op_pref_hourly_rate_min ?? '🤷'} - <DollarSign />
                {lead.job.op_pref_hourly_rate_max ?? '🤷'}
              </span>
            )}
            {lead.job.job_type === 'Fixed' && (
              <div>
                <span>
                  <DollarSign /> {lead.job.budget}
                </span>{' '}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
