import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  blankNewFilter,
  closeJob,
  fetchAllMyFiltersAsync,
  fetchDeleteFilterAsync,
  fetchFiltersCreateUpdateAsync,
  fetchFiltersJobsAsync,
  openJob,
  openTheFilter,
  selectFilters,
  selectJobs,
  selectJobStatus,
  selectOpenFilter,
  selectOpenJob,
} from '../../features/filters/filtersSlice';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useDebounce } from 'use-debounce';
import { SidePanelPartial } from '../../partials/panels/sidePanel.partial';
import { fetchCoverLetter } from '../../features/filters/filtersAPI';
import * as Yup from 'yup';
import { TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { fetchPostManualCreateLeadAsync } from '../../features/leads/leadsSlice';
import { JobDetailsPartial } from '../../partials/job/jobDetails.partial';
import { JobListItemComponent } from '../../components/lists/jobListItem.component';

interface Props {}

export const FiltersPage: FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectJobs);
  const job = useAppSelector(selectOpenJob);
  const filters = useAppSelector(selectFilters);
  const openFilter = useAppSelector(selectOpenFilter);
  const jobStatus = useAppSelector(selectJobStatus);

  useEffect(() => {
    dispatch(fetchAllMyFiltersAsync());
  }, []);

  useEffect(() => {
    if (
      openFilter &&
      openFilter !== null &&
      (!openFilter?._id || formik?.values?._id !== openFilter?._id)
    ) {
      formik.setValues(openFilter);
    }
  }, [openFilter]);

  const [coverLetter, setCoverLetter] = useState<string | undefined>(undefined);

  const formik = useFormik({
    initialValues: {
      isEnabled: false,
      filterTitle: '',

      _id: undefined,

      searchTerms: '',
      includeAnyOfSkills: '',
      excludeAnyOfSkills: '',
      minHRate: 0,
      maxHRate: 999,
      minFixRate: 0,
      maxFixRate: 999999,
      isIncludeWithoutBudget: true,
      jobType: 'Hourly',
    },
    validationSchema: Yup.object({
      filterTitle: Yup.string().min(5).label('Filter title').required(),
    }),
    onSubmit: (values) => {
      dispatch(fetchFiltersCreateUpdateAsync(values));
      formik.resetForm();
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const [formValues] = useDebounce(formik.values, 1000);

  useEffect(() => {
    dispatch(fetchFiltersJobsAsync(formValues));
  }, [formValues, dispatch]);

  useEffect(() => {
    console.log('formik.values', formValues);
  }, [formValues]);

  // useEffect(() => {
  //   (async () => {
  //     if (job?.id) {
  //       const response = await fetchCoverLetter(job.id);
  //       setCoverLetter(response.data);
  //     }
  //   })();
  // }, [job]);

  const handleFilterDelete = (f: any) => {
    if (
      window.confirm(
        `Are you sure you want to delete filter named "${f.filterTitle}"?`,
      )
    ) {
      dispatch(fetchDeleteFilterAsync(f));
    }
  };

  const [isLeadCreated, setIsLeadCreated] = useState(false);

  const handleCreateLead = (job: any) => {
    dispatch(fetchPostManualCreateLeadAsync(job));
    setIsLeadCreated(true);
  };

  return (
    <>
      {job?.id && (
        <SidePanelPartial
          isOpen={true}
          onClose={() => {
            setCoverLetter(undefined);
            setIsLeadCreated(false);
            dispatch(closeJob());
          }}
          title={job.title}
        >
          <>
            <div className="flex justify-end mb-8">
              <button
                onClick={() => handleCreateLead(job)}
                className="bg-green-700 text-white px-4 py-2"
                // disabled={isLeadCreated}
              >
                Add to Leads {isLeadCreated ? '✅' : ''}
              </button>
            </div>
            {/*{Object.keys(job).map((key) => (*/}
            {/*  <li key={key}>*/}
            {/*    <strong>{key}</strong>: {job[key]?.toString()}*/}
            {/*  </li>*/}
            {/*))}*/}

            {job && <JobDetailsPartial job={job} />}
            <pre>
              <code>{JSON.stringify(job, null, 2)}</code>
            </pre>
          </>
        </SidePanelPartial>
      )}
      <header className="bg-white shadow">
        <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            🍻 Filters
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-screen-2xl py-6 sm:px-6 lg:px-8">
          <div className="flex flex-row gap-4">
            <div className="w-1/5 border-2 rounded border-dashed p-4">
              <div className="flex justify-between mb-4">
                <span>Existing</span>{' '}
                <button onClick={() => dispatch(blankNewFilter())}>
                  <PlusCircleIcon className="w-4 h-4" />
                </button>
              </div>
              {filters?.map((f: any) => (
                <div
                  key={f._id}
                  onClick={() => dispatch(openTheFilter(f))}
                  className={`${
                    openFilter?._id === f._id
                      ? 'bg-gray-300 border-l-2 border-l-emerald-800'
                      : 'border-l-2 border-l-amber-800'
                  } text-xs p-2 flex cursor-pointer hover:bg-gray-400 hover:text-white`}
                >
                  <div className="grow">
                    <h2>
                      {f.isEnabled ? '🚀' : '💤'} {f.filterTitle ?? ' ...'}
                    </h2>
                  </div>
                  <button
                    className="grow-0"
                    onClick={() => handleFilterDelete(f)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
            <div className="w-1/5 border-2 rounded border-dashed p-4">
              {formik.errors &&
                Object.keys(formik.errors).map((key) => (
                  <div className="text-red-400 text-xs">
                    <strong>{key}</strong>: {(formik.errors as any)[key]}
                  </div>
                ))}
              <form
                onSubmit={formik.handleSubmit}
                className="space-y-2 text-xs"
              >
                <input
                  id="_id"
                  name="_id"
                  type="hidden"
                  className="w-full"
                  onChange={formik.handleChange}
                  value={formik.values._id}
                />
                <div>
                  <label htmlFor="filterTitle">Filter title</label>
                  <input
                    id="filterTitle"
                    name="filterTitle"
                    type="text"
                    className="w-full"
                    onChange={formik.handleChange}
                    value={formik.values.filterTitle}
                  />
                </div>
                <div>
                  <label htmlFor="searchTerms">Search keywords</label>
                  <input
                    id="searchTerms"
                    name="searchTerms"
                    type="text"
                    className="w-full"
                    onChange={formik.handleChange}
                    value={formik.values.searchTerms}
                  />
                </div>
                <div>
                  <label htmlFor="includeAnyOfSkills">Include skills</label>
                  <input
                    id="includeAnyOfSkills"
                    name="includeAnyOfSkills"
                    type="text"
                    className="w-full"
                    onChange={formik.handleChange}
                    value={formik.values.includeAnyOfSkills}
                  />
                </div>
                <div>
                  <label htmlFor="excludeAnyOfSkills">Exclude skills</label>
                  <input
                    id="excludeAnyOfSkills"
                    name="excludeAnyOfSkills"
                    type="text"
                    className="w-full"
                    onChange={formik.handleChange}
                    value={formik.values.excludeAnyOfSkills}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    id="isIncludeWithoutBudget"
                    name="isIncludeWithoutBudget"
                    type="checkbox"
                    onChange={formik.handleChange}
                    checked={formik.values.isIncludeWithoutBudget}
                  />
                  <label htmlFor="isIncludeWithoutBudget">
                    Include without budget
                  </label>
                </div>
                <div
                  className={formik.values.jobType !== 'Hourly' ? 'hidden' : ''}
                >
                  <label htmlFor="minHRate">Hourly rate</label>
                  <div className="flex justify-between items-center">
                    <input
                      id="minHRate"
                      name="minHRate"
                      type="number"
                      className="w-[90px] text-xs"
                      onChange={formik.handleChange}
                      value={formik.values.minHRate}
                    />
                    -
                    <input
                      id="maxHRate"
                      name="maxHRate"
                      type="number"
                      className="w-[90px] text-xs"
                      onChange={formik.handleChange}
                      value={formik.values.maxHRate}
                    />
                  </div>
                </div>
                <div
                  className={formik.values.jobType !== 'Fixed' ? 'hidden' : ''}
                >
                  <label htmlFor="minHRate">Fix rate</label>
                  <div className="flex justify-between items-center">
                    <input
                      id="minFixRate"
                      name="minFixRate"
                      type="number"
                      className="w-[90px] text-xs"
                      onChange={formik.handleChange}
                      value={formik.values.minFixRate}
                    />
                    -
                    <input
                      id="maxFixRate"
                      name="maxFixRate"
                      type="number"
                      className="w-[90px] text-xs"
                      onChange={formik.handleChange}
                      value={formik.values.maxFixRate}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="jobType">Job Type</label>
                  <div className="flex justify-between items-center">
                    <select
                      id="jobType"
                      name="jobType"
                      className="w-full"
                      onChange={formik.handleChange}
                      value={formik.values.jobType}
                    >
                      {/*<option value="any">Any</option>*/}
                      <option value="Fixed">Fixed</option>
                      <option value="Hourly">Hourly</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    id="isEnabled"
                    name="isEnabled"
                    type="checkbox"
                    onChange={formik.handleChange}
                    checked={formik.values.isEnabled}
                  />
                  <label htmlFor="isEnabled">Auto capture enabled</label>
                </div>
                <button
                  className="px-4 py-2 bg-green-700 text-white rounded-md"
                  type="submit"
                  onClick={formik.submitForm}
                >
                  Save
                </button>
              </form>
            </div>
            <div className="w-3/5 border-2 rounded border-dashed p-4">
              Results preview {jobStatus !== 'loaded' ? 'loading..' : ''}
              <ul
                role="list"
                className={`divide-y divide-gray-100 space-y-4 ${
                  jobStatus !== 'loaded' ? 'opacity-30' : ''
                }`}
              >
                {jobs?.map((job) => (
                  <JobListItemComponent job={job} key={job._id} />

                  // <li
                  //   key={job.id}
                  //   className="flex justify-between gap-x-6 py-5"
                  //   onClick={() => dispatch(openJob(job))}
                  // >
                  //   <div className="flex min-w-0 gap-x-4">
                  //     <div className="min-w-0 flex-auto">
                  //       <p className="text-sm font-semibold leading-6 text-gray-900">
                  //         {job.title}
                  //       </p>
                  //       <p className="text-xs font-semibold leading-6 text-gray-900">
                  //         {/*{DateTime.fromISO(job.date_created)*/}
                  //         {/*  .toJSDate()*/}
                  //         {/*  .toDateString()}*/}
                  //         {Math.floor(
                  //           DateTime.local().diff(
                  //             DateTime.fromISO(job.date_created),
                  //             'hours',
                  //           ).hours,
                  //         )}{' '}
                  //         hours ago
                  //       </p>
                  //
                  //       <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  //         {job.skills?.join(', ')}
                  //       </p>
                  //
                  //       <p>
                  //         Hourly rate:
                  //         {job.op_pref_hourly_rate_min} -{' '}
                  //         {job.op_pref_hourly_rate_max}
                  //       </p>
                  //
                  //       <p>Job type: {job.job_type}</p>
                  //
                  //       <p>Budget: {job.budget}</p>
                  //     </div>
                  //   </div>
                  //   <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  //     <p className="text-sm leading-6 text-gray-900">
                  //       {job.workload}
                  //     </p>
                  //     <p>
                  //       <a href={job.url} target="_blank" rel="noreferrer">
                  //         Link
                  //       </a>
                  //     </p>
                  //   </div>
                  // </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
