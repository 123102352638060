import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchLeadsList,
  fetchManualAppliedLead,
  fetchManualResetToNewLead,
  fetchPostManualCreateLead,
  fetchPostRejectLead,
} from './leadsAPI';

export interface LeadsState {
  value: any;
  openLead: any;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: LeadsState = {
  value: undefined,
  openLead: undefined,
  status: 'idle',
};

export const fetchLeadsListAsync = createAsyncThunk(
  'leads/fetchLeadsList',
  async () => {
    const response = await fetchLeadsList();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchPostManualCreateLeadAsync = createAsyncThunk(
  'leads/fetchPostManualCreateLeadAsync',
  async (job: any) => {
    const response = await fetchPostManualCreateLead(job);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchPostRejectLeadAsync = createAsyncThunk(
  'leads/fetchPostManualCreateLeadAsync',
  async (leadId: string, thunkAPI) => {
    const response = await fetchPostRejectLead(leadId);
    thunkAPI.dispatch(fetchLeadsListAsync());
    return response.data;
  },
);

export const fetchManualAppliedLeadAsync = createAsyncThunk(
  'leads/fetchManualAppliedLead',
  async (leadId: string, thunkAPI) => {
    const response = await fetchManualAppliedLead(leadId);
    thunkAPI.dispatch(fetchLeadsListAsync());
    return response.data;
  },
);

export const fetchManualResetToNewLeadAsync = createAsyncThunk(
  'leads/fetchManualResetToNewLeadAsync',
  async (leadId: string, thunkAPI) => {
    const response = await fetchManualResetToNewLead(leadId);
    thunkAPI.dispatch(fetchLeadsListAsync());
    return response.data;
  },
);

export const leadsSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    openLead: (state, action) => {
      state.openLead = action.payload;
    },
    closeLead: (state) => {
      state.openLead = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeadsListAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.value = action.payload;
      })
      .addCase(fetchLeadsListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPostRejectLeadAsync.fulfilled, (state) => {
        state.openLead = undefined;
      })
      .addCase(fetchManualAppliedLeadAsync.fulfilled, (state) => {
        state.openLead = undefined;
      })
      .addCase(fetchManualResetToNewLeadAsync.fulfilled, (state) => {
        state.openLead = undefined;
      });
  },
});

export const { openLead, closeLead } = leadsSlice.actions;

export const selectLeads = (state: RootState) => state.leads.value;
export const selectOpenLead = (state: RootState) => state.leads.openLead;
export const selectLeadsStatus = (state: RootState) => state.experience.status;

export default leadsSlice.reducer;
