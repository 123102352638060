import { FC, useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { resetAxios } from '../lib/axios.lib';
import { LoginFormPartial } from '../partials/forms/auth/login.form.partial';

interface Props {}

export const LoginPage: FC<Props> = ({}) => {
  let auth = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.ref) {
      navigate('/');
    } else {
      resetAxios();
    }
  }, [auth, navigate]);

  return (
    <>
      <LoginFormPartial />
    </>
  );
};
