import { FC } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { QuestionMarkCircleIcon, ClockIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { openJob } from '../../features/filters/filtersSlice';

interface Props {
  job: any;
}

export const JobListItemComponent: FC<Props> = ({ job }) => {
  const dispatch = useAppDispatch();

  const hoursAgo = Math.floor(
    DateTime.local().diff(
      DateTime.fromISO(job?.date_created ?? job?.op_ctime ?? job.createdAt),
      'hours',
    ).hours,
  );

  const DollarSign = () => <span className="font-bold text-green-700">$</span>;

  return (
    <div
      className="bg-white p-4 border-2 border-dashed border-gray-200 rounded-md w-full"
      onClick={() => dispatch(openJob(job))}
    >
      <div>
        <h2 className="text-sm font-bold">{job?.title ?? job?.op_title}</h2>
        <div className="my-2">
          <p className="mt-1 truncate text-xs leading-5 text-gray-500 flex flex-wrap gap-2">
            {job.skills?.map((skill: any) => (
              <span
                key={skill}
                className="py-1 px-2 bg-green-100 text-gray-600 text-xs rounded-2xl"
              >
                {skill.trim()}
              </span>
            ))}
          </p>
        </div>
      </div>
      <div className="flex items-center mt-3 justify-between">
        <div
          className={`flex items-center gap-1  ${
            hoursAgo > 12 ? 'text-red-800' : ''
          }`}
        >
          <ClockIcon className="w-4 h-4" />
          <span className="text-xs font-bold">
            {DateTime.fromISO(
              job?.date_created ?? job?.op_ctime ?? job.createdAt,
            ).toRelative()}
          </span>
        </div>
        {job?._id && (
          <div className="text-xs">
            {job.job_type === 'Hourly' && (
              <span>
                <DollarSign />
                {job.op_pref_hourly_rate_min ?? '🤷'} - <DollarSign />
                {job.op_pref_hourly_rate_max ?? '🤷'}
              </span>
            )}
            {job.job_type === 'Fixed' && (
              <div>
                <span>
                  <DollarSign /> {job.budget}
                </span>{' '}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
