import { FC, useEffect, useState } from 'react';
import { LinkIcon } from '@heroicons/react/24/outline';
import { fetchCoverLetter } from '../../features/filters/filtersAPI';

interface Props {
  job: any;
}

export const JobDetailsPartial: FC<Props> = ({ job }) => {
  const [coverLetter, setCoverLetter] = useState('');

  const handleClickCoverLetter = async () => {
    if (job?.id) {
      const response = await fetchCoverLetter(job.id);
      setCoverLetter(response.data);
    }
  };

  return (
    <div className="mb-8 pb-8 border-b-2 border-b-dashed">
      <header className="flex flex-col gap-2">
        <div className="flex items-center  mb-4">
          <h1 className="text-xl font-bold">{job.title ?? job.op_title}</h1>
          <a href={job.url} className="pl-2" target="_blank" rel="noreferrer">
            <LinkIcon className="w-5 h-5" />
          </a>
        </div>
        <div className="flex items-center">
          <div className="text-white bg-amber-950 py-2 px-4 font-bold items-center flex gap-2">
            {job.job_type === 'Hourly' && (
              <span>
                💵 ${job.op_pref_hourly_rate_min} - $
                {job.op_pref_hourly_rate_max}
              </span>
            )}
            <div>
              {job.job_type === 'Fixed' && <span>💵 ${job.budget}</span>}
            </div>
            <span className="text-sm">{job.job_type}</span>
          </div>

          <div className="flex gap-2 bg-gray-100  py-2 px-4 ">
            <div className="font-bold">
              {job.category2 ?? job.job_category_level_one}
            </div>{' '}
            ➡{' '}
            <div className="font-medium">
              {job.subcategory2 ?? job.job_category_level_two}
            </div>
          </div>
          <div className="font-medium border-l-2 pl-2 py-2 px-4 bg-gray-200">
            {job.publish_time}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 text-xs">
          {job.skills.map((skill: any) => (
            <div key={skill} className="text-white px-2 py-1 bg-green-700">
              {skill}
            </div>
          ))}
        </div>
        <div>{job.workload}</div>
      </header>
      <div className="text-xs p-4 bg-black text-white">
        <div>
          {[
            job.buyer?.op_city,
            job.buyer?.op_state,
            job.buyer?.op_country,
            job.buyer?.op_timezone,
          ]
            .filter(Boolean)
            .join(', ')}
          {/*{job.buyer.op_city}, {job.buyer.op_state}, {job.buyer.op_country},*/}
          {/*{job.buyer.op_timezone}*/}
        </div>
        <div>Score: {job.buyer?.op_adjusted_score}</div>
        <div>Open jobs: {job.buyer?.op_tot_jobs_open}</div>
        <div>Total jobs: {job.buyer?.op_tot_jobs_posted}</div>
        <div>Spent: ${job.buyer?.op_tot_charge}</div>
        <div>Hours: {job.buyer?.op_tot_hours}</div>
        <div>Contract date: {job.buyer?.op_contract_date}</div>
        <div>Past hires: {job.client?.past_hires}</div>
        <div>
          Payment verification status: {job.client?.payment_verification_status}
        </div>
      </div>
      <article>
        <div className="whitespace-break-spaces border-2 bg-gray-100 border-dashed rounded-2xl p-4 mt-4 text-sm">
          {job.snippet}
        </div>
      </article>
      <div>
        <button onClick={handleClickCoverLetter}>generate cover letter</button>
        <div>{coverLetter}</div>
      </div>
    </div>
  );
};
