import axios from 'axios';

export const fetchFiltersJobs = async (filter: any) => {
  const r = await axios.post('filter/jobs', filter);
  return r;
};

export const fetchCoverLetter = async (id: string) => {
  const r = await axios.get(`filter/cover_letter_1`, { params: { id } });
  return r;
};

export const fetchCoverLetterFromContent = async (content: {
  companyName: string;
  companyWebsite: string;
  jobDescription: string;
}) => {
  const r = await axios.post(`filter/cover_letter_from_content`, content);
  return r;
};

export const fetchAllMyFilters = async () => {
  const r = await axios.get(`filter/my_filters`);
  return r;
};

export const fetchFiltersCreateUpdate = async (filter: any) => {
  const r = await axios.post('filter/edit_filter', filter);
  return r;
};

export const fetchDeleteFilter = async (f: any) => {
  const r = await axios.delete(`filter/del_filter/${f._id}`);
  return r;
};
