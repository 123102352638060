import { ReactNode, FC } from 'react';

interface Props {
  children: ReactNode[] | ReactNode;
  title: string;
}

export const ColumnComponent: FC<Props> = ({ children, title }) => {
  return (
    <div className=" p-4 border-2 border-dashed border-gray-200 rounded-md grow h-full">
      <h2 className="text-lg font-bold">{title}</h2>
      <div className="border-b-[1px] mt-2 mb-4 w-[250px]" />
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
};
