import axios from 'axios';

export const fetchUserProfile = async () => {
  const profileResponse = await axios.get('auth/profile');
  return profileResponse;
};

export const fetchAuthorization = async (code: string) => {
  const profileResponse = await axios.post('auth/login', { code });
  return profileResponse;
};
