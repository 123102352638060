import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchExperienceList,
  fetchPostExperience,
  fetchDeleteExperience,
} from './experienceAPI';

export interface ExperienceState {
  value: any;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: ExperienceState = {
  value: undefined,
  status: 'idle',
};

export const fetchExperienceListAsync = createAsyncThunk(
  'experience/fetchExperienceList',
  async () => {
    const response = await fetchExperienceList();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchPostExperienceAsync = createAsyncThunk(
  'experience/fetchPostExperience',
  async (e: any, thunkAPI) => {
    const response = await fetchPostExperience(e);
    thunkAPI.dispatch(fetchExperienceListAsync());
    return response.data;
  },
);

export const fetchDeleteExperienceAsync = createAsyncThunk(
  'experience/fetchDeleteExperience',
  async (e: any, thunkAPI) => {
    const response = await fetchDeleteExperience(e);
    thunkAPI.dispatch(fetchExperienceListAsync());
    return response.data;
  },
);

export const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // openJob: (state, action) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.openJob = action.payload;
    // },
    // closeJob: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.openJob = undefined;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperienceListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExperienceListAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.value = action.payload;
      })
      .addCase(fetchExperienceListAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// export const {  } = experienceSlice.actions;

export const selectExperience = (state: RootState) => state.experience.value;
export const selectExperienceStatus = (state: RootState) =>
  state.experience.status;

export default experienceSlice.reducer;
