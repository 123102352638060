import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchAllMyFilters,
  fetchCoverLetter,
  fetchDeleteFilter,
  fetchFiltersCreateUpdate,
  fetchFiltersJobs,
} from './filtersAPI';
import { stat } from 'fs';

export interface FiltersState {
  value: any;
  jobs: any[];
  openJob: any;
  openFilter: any;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: FiltersState = {
  value: undefined,
  jobs: [],
  openJob: undefined,
  openFilter: undefined,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchFiltersJobsAsync = createAsyncThunk(
  'filters/fetchFiltersJobs',
  async (filter: any) => {
    const response = await fetchFiltersJobs(filter);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchAllMyFiltersAsync = createAsyncThunk(
  'filters/fetchAllMyFilters',
  async () => {
    const response = await fetchAllMyFilters();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const fetchDeleteFilterAsync = createAsyncThunk(
  'filters/fetchDeleteFilter',
  async (f: any, thunkAPI) => {
    const response = await fetchDeleteFilter(f);
    // The value we return becomes the `fulfilled` action payload
    thunkAPI.dispatch(fetchAllMyFiltersAsync());
    return response.data;
  },
);

export const fetchFiltersCreateUpdateAsync = createAsyncThunk(
  'filters/fetchFiltersCreateUpdate',
  async (filter: any, thunkAPI) => {
    const response = await fetchFiltersCreateUpdate(filter);
    thunkAPI.dispatch(fetchAllMyFiltersAsync());
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

// export const fetchCoverLetterAsync = createAsyncThunk(
//   'filters/fetchFiltersJobs',
//   async (id: string) => {
//     const response = await fetchCoverLetter(id);
//     return response.data;
//   },
// );

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    openJob: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.openJob = action.payload;
    },
    closeJob: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.openJob = undefined;
    },
    openTheFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.openFilter = action.payload;
      state.status = 'idle';
    },
    closeTheFilter: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.openFilter = undefined;
    },
    blankNewFilter: (state) => {
      state.openFilter = {
        filterTitle: 'New',

        includeAnyOfWords: [],
        excludeAnyOfWords: [],
        includeAnyOfSkills: [],
        excludeAnyOfSkills: [],
        categories: [],
        minHRate: 40,
        maxHRate: 999,
        minFixRate: 0,
        maxFixRate: 999999,
        isIncludeWithoutBudget: true,
        projectDuration: [],
        hourlyWorkload: [],
        includeClientLocations: [],
        excludeClientLocations: [],
        preferredFreelancerLocations: [],
        talentType: [],
        experienceLevel: [],
        isEnabled: false,
        searchTerms: 'next.js',
        jobType: 'Hourly',
      };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiltersJobsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFiltersJobsAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.jobs = action.payload;
      })
      .addCase(fetchFiltersJobsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAllMyFiltersAsync.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(fetchFiltersCreateUpdateAsync.fulfilled, (state, action) => {
        state.openFilter = action.payload;
      })
      .addCase(fetchDeleteFilterAsync.fulfilled, (state, action) => {
        state.openFilter = undefined;
      });
  },
});

export const {
  openJob,
  closeJob,
  openTheFilter,
  closeTheFilter,
  blankNewFilter,
} = filtersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFilters = (state: RootState) => state.filters.value;
export const selectJobs = (state: RootState) => state.filters.jobs;
export const selectJobStatus = (state: RootState) => state.filters.status;
export const selectOpenJob = (state: RootState) => state.filters.openJob;
export const selectOpenFilter = (state: RootState) => state.filters.openFilter;

export default filtersSlice.reducer;
