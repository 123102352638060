import { FC } from 'react';

interface Props {}

export const DashboardPage: FC<Props> = ({}) => {
  return (
    <>
      <header className="bg-white shadow">
        <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Dashboard
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-screen-2xl py-6 sm:px-6 lg:px-8">
          Dashboardijus
        </div>
      </main>
    </>
  );
};
