import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { fetchCoverLetterFromContent } from '../../features/filters/filtersAPI';

export const CoverLetterPage = () => {
  const [coverLetter, setCoverLetter] = useState('');

  const [status, setStatus] = useState<'idle' | 'loading' | 'loaded'>('idle');

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyWebsite: '',
      jobDescription: '',
    },
    validationSchema: Yup.object({
      jobDescription: Yup.string().min(5).label('Job description').required(),
      companyName: Yup.string().label('Company name').optional(),
      companyWebsite: Yup.string().url('I thould be url').optional(),
    }),
    onSubmit: async (values) => {
      setStatus('loading');
      const response = await fetchCoverLetterFromContent(values);
      setStatus('loaded');
      setCoverLetter(response.data);
    },
  });

  const handleClearClick = () => {
    formik.resetForm();
    setCoverLetter('');
    setStatus('idle');
  };

  return (
    <>
      <header className="bg-white shadow">
        <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Cover letter generator
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-screen-2xl py-6 sm:px-6 lg:px-8">
          <form onSubmit={formik.handleSubmit} className="space-y-2 text-xs">
            <div>
              {formik.errors &&
                Object.keys(formik.errors).map((k) => (
                  <div className="text-red-900 text-xs">
                    {/*// @ts-ignore*/}
                    <strong>{k}</strong>: {formik.errors[k]}
                  </div>
                ))}
            </div>

            <div>
              <label htmlFor="filterTitle">Company name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                className="w-full text-xs"
                onChange={formik.handleChange}
                value={formik.values.companyName}
              />
            </div>

            {/*<div>*/}
            {/*  <label htmlFor="filterTitle">Company website url</label>*/}
            {/*  <input*/}
            {/*    type="url"*/}
            {/*    id="companyWebsite"*/}
            {/*    name="companyWebsite"*/}
            {/*    className="w-full text-xs"*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    value={formik.values.companyWebsite}*/}
            {/*  />*/}
            {/*</div>*/}

            <div>
              <label htmlFor="filterTitle">
                Paste job desription here (required)
              </label>
              <textarea
                id="jobDescription"
                name="jobDescription"
                className="w-full text-xs"
                rows={12}
                onChange={formik.handleChange}
                value={formik.values.jobDescription}
              />
            </div>
            <div className="flex justify-between mt-4">
              <button
                className="px-4 py-2 bg-red-700 text-white"
                onClick={handleClearClick}
              >
                Clear
              </button>
              <button
                className="px-4 py-2 bg-green-700 text-white"
                // onClick={formik.submitForm}
              >
                {coverLetter ? 'Re-submit' : 'Submit'}
              </button>
            </div>
          </form>

          <div className="mt-8">
            <div>
              <div>
                <label htmlFor="filterTitle">Generated cover letter</label>
                <button
                  onClick={() => navigator.clipboard.writeText(coverLetter)}
                >
                  <ClipboardDocumentIcon className="w-4 h-4" />
                </button>
              </div>
              <article className="whitespace-break-spaces text-sm p-4 bg-white border-2 border-dashed border-gray-200 rounded-2xl">
                {status !== 'loading' && coverLetter}
                {status === 'loading' && (
                  <div className="flex justify-center">
                    <img
                      className="w-1/3 h-auto"
                      src="/thinking-gif-2018-40.gif"
                      alt="Thonking"
                    />
                  </div>
                )}
              </article>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
