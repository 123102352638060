export const profileQuestionnaireLib: {
  [key: string]: { [key: string]: string };
} = {
  Links: {
    LinkedIn: 'What is your linkedin profile URL?',
    GitHub: 'What is your Github profile URL?',
    Twitter: 'What is your Twitter profile URL?',
    Website: 'What is your Website URL?',
    Blog: 'What is your Blog URL?',
    Portfolio: 'What is your Portfolio URL?',
  },
  Technologies: {
    PrimaryTechStack:
      'What is your primary technology stack? What are your main go to tools?',
    SecondaryTechStack:
      'What is your secondary technology stack that you have some experience with?',
    OtherTools: 'What other tools do you use in your work?',
  },
  ApplicantsBackground: {
    FullName: 'What is your full name?',
    EducationLevel: "What's your highest level of education?",
    FieldOfEducation: 'In which field is your highest qualification?',
    PrimarySkills: 'List your primary skills or expertise areas.',
    TotalWorkExperience: 'What is your total work experience in years?',
    Achievements:
      'Describe any specific achievements or accomplishments in your career.',
    LeadershipRoles:
      'Have you held any leadership or managerial roles? If so, provide details.',
    Certifications:
      'Mention any certifications or additional qualifications you possess.',
    Languages: 'List any languages you speak and your proficiency levels.',
    Publications:
      'Do you have any publications, presentations, or notable projects?',
  },
  JobSpecificDetails: {
    JobTitle: "What is the job title you're applying for?",
    Responsibilities: 'Describe the main responsibilities or tasks of the job.',
    Industry: 'What industry or sector does this job belong to?',
    CompanyName: 'Name the company or organization offering the job.',
    AddressedTo:
      'Is there a specific person or department the cover letter should be addressed to?',
    CompanyValues: 'What values or qualities does the company emphasize?',
    Qualifications:
      'Are there specific qualifications or skills highlighted for the role?',
    JobLocation: 'Is the role a remote position, in-office, or hybrid?',
  },
  PersonalAttributes: {
    SelfDescription: 'Describe yourself in three words.',
    HandlingStress: 'How do you handle stress or high-pressure situations?',
    TeamExperience:
      "Provide an instance where you've worked in a team and faced challenges.",
    LeadershipScenario:
      'Describe a scenario where you took the initiative or displayed leadership.',
    SoftSkills: 'Mention any soft skills you believe you excel in.',
  },
  CustomizationPreferences: {
    Tone: 'What tone do you prefer your cover letter to have?',
    Emphasize:
      "Are there specific experiences or skills you'd like to emphasize?",
    PersonalAnecdotes:
      "Are there any personal anecdotes or stories you'd like to include?",
    QuestionsForEmployer:
      "Do you have any questions or points you'd like the potential employer to consider?",
    CallToAction:
      'Would you like the cover letter to end with a call to action?',
  },
  AdditionalInformation: {
    OtherInformation:
      "Is there any other information, experience, or background you'd like to share?",
    EmploymentGaps:
      "Mention any gaps in employment and the reasons, if you'd like them addressed.",
    IndustrySwitch:
      'Are you applying for a job in a different industry or role than your previous experience? If yes, explain the reason for the switch.',
  },
  CareerGoals: {
    ShortTerm: 'What are your short-term career goals?',
    LongTerm: 'What are your long-term career aspirations?',
    Motivations: 'What motivates you in your professional life?',
    Passions:
      "Are there particular industries, technologies, or trends you're passionate about?",
  },
  JobFit: {
    Interest: 'Why are you particularly interested in this role?',
    Discovery: 'How did you hear about this job position?',
    CompanyResonance:
      "What aspects of the company's culture or mission resonate with you?",
    Familiarity:
      "Are you familiar with the company's products, services, or projects?",
  },
  References: {
    Availability:
      'Do you have references or individuals who can vouch for your qualifications and character?',
    Testimonials:
      'Have you received any testimonials or commendations from previous employers or colleagues?',
  },
  PersonalInterests: {
    FreeTime: 'What do you enjoy doing in your free time?',
    PersonalProjects:
      'Are there any personal projects or hobbies that have helped you develop skills relevant to the job?',
  },
  ChallengesGrowth: {
    PastChallenges:
      'Describe a challenge you faced in a previous role and how you overcame it.',
    ContinuousLearning:
      'How do you approach continuous learning and professional development?',
    Feedback:
      'Have you ever received feedback on areas to improve? How did you address it?',
  },
  RelocationPreferences: {
    WillingnessToRelocate:
      'Are you willing to relocate for the job, if required?',
    WorkHours: 'Do you have any preferred work hours or schedules?',
    Travel: 'Are you open to frequent travel, if the role demands it?',
  },
  DiversityInclusion: {
    Contribution:
      'How do you contribute to fostering a diverse and inclusive workplace?',
    DiverseInitiatives:
      "Are there any experiences where you've actively promoted or been part of diverse initiatives?",
  },
  AdditionalQualifications: {
    Workshops:
      'Have you attended any workshops, seminars, or conferences relevant to the job?',
    ProfessionalMemberships:
      'Are you a member of any professional organizations or networks?',
  },
  PersonalTouch: {
    UniqueSellingProposition:
      'Why should the employer choose you over other candidates?',
    Mantra:
      'Do you have a personal mantra, quote, or philosophy that guides your professional decisions?',
  },
  Closure: {
    Highlights:
      "Are there specific points you'd like to highlight in the conclusion of your cover letter?",
    ContactPreferences:
      'How do you prefer to be contacted for follow-ups or interviews?',
  },
};
